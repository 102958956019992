import { GraphQLClient } from 'graphql-request';
import * as Dom from 'graphql-request/dist/types.dom';
import gql from 'graphql-tag';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type ChangePasswordInput = {
  /** current password */
  currentPassword: Scalars['String'];
  /** new password */
  newPassword: Scalars['String'];
};


export type Jwt = {
  __typename?: 'JWT';
  accessToken: Scalars['String'];
  expiresAt: Scalars['Float'];
  refreshToken: Scalars['String'];
  refreshTokenExpiresAt: Scalars['Float'];
};

export type LoginWithEmailInput = {
  /** username */
  username: Scalars['String'];
  /** password */
  password: Scalars['String'];
  /** A random device id using uuid v4 */
  deviceId: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  login: Jwt;
  /** Refresh token */
  refreshToken: Jwt;
  changePassword: Scalars['Boolean'];
  logout: Scalars['Boolean'];
  makeAuthorizationCode: Scalars['String'];
};


export type MutationLoginArgs = {
  input: LoginWithEmailInput;
};


export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};


export type MutationChangePasswordArgs = {
  input: ChangePasswordInput;
};

export type Query = {
  __typename?: 'Query';
  me: User;
};

export type RefreshTokenInput = {
  /** Refresh token */
  refreshToken: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  _id: Scalars['ID'];
  fullName: Scalars['String'];
  username: Scalars['String'];
  address?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  type?: Maybe<UserType>;
  avatarId?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isSuperAdmin?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export enum UserType {
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Staff = 'STAFF'
}

export type LoginMutationVariables = Exact<{
  input: LoginWithEmailInput;
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'accessToken' | 'expiresAt' | 'refreshToken' | 'refreshTokenExpiresAt'>
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'logout'>
);

export type RefreshTokenMutationMutationVariables = Exact<{
  input: RefreshTokenInput;
}>;


export type RefreshTokenMutationMutation = (
  { __typename?: 'Mutation' }
  & { refreshToken: (
    { __typename?: 'JWT' }
    & Pick<Jwt, 'accessToken' | 'expiresAt' | 'refreshToken' | 'refreshTokenExpiresAt'>
  ) }
);

export type MakeAuthorizationCodeMutationVariables = Exact<{ [key: string]: never; }>;


export type MakeAuthorizationCodeMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'makeAuthorizationCode'>
);


export const LoginDocument = gql`
    mutation Login($input: LoginWithEmailInput!) {
  login(input: $input) {
    accessToken
    expiresAt
    refreshToken
    refreshTokenExpiresAt
  }
}
    `;
export const LogoutDocument = gql`
    mutation logout {
  logout
}
    `;
export const RefreshTokenMutationDocument = gql`
    mutation RefreshTokenMutation($input: RefreshTokenInput!) {
  refreshToken(input: $input) {
    accessToken
    expiresAt
    refreshToken
    refreshTokenExpiresAt
  }
}
    `;
export const MakeAuthorizationCodeDocument = gql`
    mutation MakeAuthorizationCode {
  makeAuthorizationCode
}
    `;

export type SdkFunctionWrapper = <T>(action: (requestHeaders?:Record<string, string>) => Promise<T>, operationName: string) => Promise<T>;


const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action();

export function getSdk(client: GraphQLClient, withWrapper: SdkFunctionWrapper = defaultWrapper) {
  return {
    Login(variables: LoginMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LoginMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LoginMutation>(LoginDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'Login');
    },
    logout(variables?: LogoutMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<LogoutMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<LogoutMutation>(LogoutDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'logout');
    },
    RefreshTokenMutation(variables: RefreshTokenMutationMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<RefreshTokenMutationMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<RefreshTokenMutationMutation>(RefreshTokenMutationDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'RefreshTokenMutation');
    },
    MakeAuthorizationCode(variables?: MakeAuthorizationCodeMutationVariables, requestHeaders?: Dom.RequestInit["headers"]): Promise<MakeAuthorizationCodeMutation> {
      return withWrapper((wrappedRequestHeaders) => client.request<MakeAuthorizationCodeMutation>(MakeAuthorizationCodeDocument, variables, {...requestHeaders, ...wrappedRequestHeaders}), 'MakeAuthorizationCode');
    }
  };
}
export type Sdk = ReturnType<typeof getSdk>;