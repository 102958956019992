// import { ResetPasswordInput } from '@/graphql/generated/graphql';
import { Jwt } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export interface RefreshTokenState {
  loading: boolean;
  data?: Jwt;
}
export interface RefreshTokenAction {
  type: typeof REFRESH_TOKEN;
}
export interface RefreshTokenSuccess {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: Jwt;
}
export interface RefreshTokenError {
  type: typeof REFRESH_TOKEN_ERROR;
  payload: AppError;
}
export type RefreshTokenActionTypes = RefreshTokenAction | RefreshTokenSuccess | RefreshTokenError;
