import { getSDK } from '@/graphql/client';

export const MakeAuthorizationCodeApi = async () => {
  return new Promise((resolve, reject) => {
    getSDK()
      .MakeAuthorizationCode()
      .then((code) => {
        resolve(code);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};
