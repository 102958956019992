import { getSDK } from '@/graphql/client';
import { LoginWithEmailInput } from '@/graphql/generated/graphql';

export const me = async () => {
  //   return new Promise((resolve, reject) => {
  //     //call api
  //   });
};

// export const login = async (payload: LoginInput) => {
//   return new Promise((resolve, reject) => {
//     //call api
//     getSDK()
//       .login({ input: payload })
//       .then((user) => {
//         resolve(user);
//       })
//       .catch((error) => reject(error?.response?.errors[0]));
//   });
// };

export const login = async (payload: LoginWithEmailInput) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK()
      .Login({ input: payload })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const logoutApi = async () => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK(true)
      .logout()
      .then(() => {
        resolve(true);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};

export const refreshTokenApi = async (refreshToken: string) => {
  return new Promise((resolve, reject) => {
    //call api
    getSDK(true)
      .RefreshTokenMutation({ input: { refreshToken } })
      .then((user) => {
        resolve(user);
      })
      .catch((error) => reject(error?.response?.errors[0]));
  });
};
