import env from '@/env';
import LogoutIcon from '@assets/images/icons/sign-out.svg';
import logo_win_flash from '@assets/images/logo_login.png';
import logo_win_booking from '@assets/images/logo_win_booking.png';
import Loading from '@commons/components/Loading';
import { setTitle } from '@helpers/dom';
import useLogout from '@modules/Auth/hooks/useLogout';
import { refreshToken } from '@modules/Auth/redux/actions';
import { RootState } from '@redux/reducers';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import useMakeAuthCode from './hooks/useMakeAuthCode';
import './styles.scss';

export enum SelectType {
  BOOKING = 'BOOKING',
  FLASH_ORDER = 'FLASH_ORDER',
}

function HomePage() {
  const { onLogout } = useLogout();
  const { code, loading, onMakeCode } = useMakeAuthCode();
  const [selectedType, setSelectedType] = useState<SelectType>();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const isLogout = new URLSearchParams(search).get('isLogout');
  const appLoaded = useSelector<RootState>((state) => state.app.loaded);

  useEffect(() => {
    setTitle('Homepage');
  }, []);

  const IntervalRefreshToken = useRef<any>();
  const minutesRefresh = 10 * 60 * 1000; //10p

  useEffect(() => {
    if (appLoaded) {
      IntervalRefreshToken.current = setInterval(() => {
        dispatch(refreshToken());
      }, minutesRefresh);
    }
    return () => {
      IntervalRefreshToken.current && clearInterval(IntervalRefreshToken.current);
    };
    // eslint-disable-next-line
  }, [appLoaded]);

  const handleLogout = () => {
    onLogout();
  };

  useEffect(() => {
    if (appLoaded && isLogout === 'true') {
      handleLogout();
    } // eslint-disable-next-line
  }, [isLogout, appLoaded]);

  const onSelect = (type: SelectType) => {
    setSelectedType(type);
    onMakeCode();
  };

  useEffect(() => {
    if (code) {
      if (selectedType === SelectType.BOOKING) {
        window.location.href = `${env.urlWinBooking}/login?code=${code}`;
      } else if (selectedType === SelectType.FLASH_ORDER) {
        window.location.href = `${env.urlWinFlashOrder}/login?code=${code}`;
      }
    }
    // eslint-disable-next-line
  }, [code]);

  return (
    <>
      {loading && <Loading type="fullpage"></Loading>}
      <div className="home-page">
        <div className="btn-logout" onClick={handleLogout}>
          <img src={LogoutIcon} alt="Logout" />
        </div>
        <div className="container_logo">
          <div
            className="logo_item"
            onClick={() => {
              onSelect(SelectType.BOOKING);
            }}
          >
            <img src={logo_win_booking} alt="win-booking" />
          </div>

          <div
            className="logo_item"
            onClick={() => {
              onSelect(SelectType.FLASH_ORDER);
            }}
          >
            <img src={logo_win_flash} alt="win-flash" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePage;
