import { useState } from 'react';
import { MakeAuthorizationCodeApi } from '../services/apis';

const useMakeAuthCode = () => {
  const [loading, setLoading] = useState<Boolean>(false);
  const [code, setCode] = useState<string>();
  const onMakeCode = async () => {
    setLoading(true);
    return await MakeAuthorizationCodeApi()
      .then((code: any) => {
        setLoading(false);
        if (code) {
          setCode(code.makeAuthorizationCode);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  return {
    onMakeCode,
    loading,
    code,
  };
};

export default useMakeAuthCode;
