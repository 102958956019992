import { protectedComponent } from '@hoc/protectedComponent';
import HomePage from '@modules/Home/index';

export const HomeRouter = [
  {
    path: '/',
    exact: true,
    component: protectedComponent(HomePage),
  },
];
