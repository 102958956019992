// import { Company, Jwt, LoginInput, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPDATE_PROFILE_SUCCESS } from './update';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export interface AuthData {
  token: string;
  refreshToken: string;
  uid: string;
  expiresAt: number;
  refreshTokenExpiresAt: number;
}

export const Languages = [
  {
    key: 'no',
    name: 'Norwegian',
  },
  {
    key: 'en',
    name: 'English',
  },
  {
    key: 'vi',
    name: 'Vietnamese',
  },
];

export interface UserData {
  companyId?: string | null;
}

export interface LoginData {
  login: any;
}
export interface LoginState {
  loading: boolean;
  data?: UserData;
}

export interface LoginInput {
  username: string;
  password: string;
}

export interface LoginAction {
  type: typeof LOGIN;
  payload: LoginInput;
}

export interface LoginActionSuccess {
  type: typeof LOGIN_SUCCESS;
  payload: any;
}

export interface LoginActionError {
  type: typeof LOGIN_ERROR;
  payload: AppError;
}

export interface UpdateProfileSuccessListener {
  type: typeof UPDATE_PROFILE_SUCCESS;
  payload: any;
}

export type LoginActionTypes = LoginAction | LoginActionSuccess | LoginActionError | UpdateProfileSuccessListener;
