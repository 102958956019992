import { Jwt, User } from '@/graphql/generated/graphql';
import { AppError } from '@commons/types';
import { UPDATE_PROFILE_SUCCESS } from '../action-types';
import { LOGIN, LoginActionTypes, LoginInput, LOGIN_ERROR, LOGIN_SUCCESS } from '../action-types/login';

export const login = (payload: LoginInput): LoginActionTypes => ({
  type: LOGIN,
  payload,
});

export const loginError = (payload: AppError): LoginActionTypes => ({
  type: LOGIN_ERROR,
  payload,
});

export const loginSuccess = (payload: Jwt): LoginActionTypes => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const updateProfileLoginSuccess = (payload: User): LoginActionTypes => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload,
});
