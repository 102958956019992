import { AppError } from '@commons/types';
import { LOGOUT, LogoutActionTypes, LOGOUT_ERROR, LOGOUT_SUCCESS } from '../action-types/logout';
export const logout = (): LogoutActionTypes => ({
  type: LOGOUT,
});
export const logoutSuccess = (): LogoutActionTypes => ({
  type: LOGOUT_SUCCESS,
});
export const logoutError = (payload: AppError): LogoutActionTypes => ({
  type: LOGOUT_ERROR,
  payload,
});
