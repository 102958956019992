import { getHistory } from '@/helpers/history';
import { CommonPath } from '@commons/base-routes';
import { checkRefreshToken, removeToken, setAuthData } from '@helpers/token';
import * as apis from '@modules/Auth/services/apis';
import { put } from 'redux-saga/effects';
import { RefreshTokenAction } from '../action-types/refresh-token';
import { refreshTokenError, refreshTokenSuccess } from '../actions/refresh-token';

export function* refreshTokenSaga(action: RefreshTokenAction): any {
  try {
    const isRefreshToken = checkRefreshToken();
    if (isRefreshToken) {
      const data: any = yield apis.refreshTokenApi(isRefreshToken.refreshToken);
      setAuthData(data.refreshToken);
      yield put(refreshTokenSuccess(data.refreshToken));
    }
  } catch (error) {
    yield put(refreshTokenError(error));
    removeToken();
    getHistory().push(CommonPath.LOGIN_PATH);
  }
}
