import { getHistory, getRedirectUrl } from '@/helpers/history';
import {
  getAuthLocalData,
  getDeviceId,
  getLoginStorageData,
  setAuthData,
  setDeviceId,
  setWsLink,
  tokenChecker,
} from '@/helpers/token';
import { CommonPath } from '@commons/base-routes';
import { NotificationSuccess } from '@commons/components/Notification';
import { put, take } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';
import * as apis from '../../services/apis';
import { LoginAction, LoginActionSuccess, LoginData, LOGOUT } from '../action-types';
import { loginError, loginSuccess, refreshToken } from '../actions';

export function* loginAsync(action: LoginAction) {
  let deviceId = getDeviceId();

  if (!deviceId) {
    deviceId = uuidv4();
    setDeviceId(deviceId || '');
  }

  try {
    const data: LoginData = yield apis.login({ ...action.payload, deviceId });
    setAuthData(data.login);
    yield put(loginSuccess(data.login));
    NotificationSuccess('notification', 'login_success');
  } catch (error) {
    yield put(loginError(error));
  }
}

export function loginSuccessAsync(action: LoginActionSuccess) {
  setWsLink(getAuthLocalData()?.accessToken);

  const redirectUrl = getRedirectUrl();
  if (redirectUrl !== 'noredirect') {
    if (redirectUrl) {
      const rUrl = new URL(redirectUrl);
      const cOrigin = window.location.origin;
      if (cOrigin === rUrl.origin) {
        const newPath = rUrl.pathname + rUrl.search;
        if (newPath.indexOf(CommonPath.LOGIN_PATH) === 0) {
          getHistory().replace(CommonPath.DEFAULT_PATH);
        } else {
          getHistory().replace(newPath);
        }
      } else {
        window.location.href = redirectUrl;
      }
    } else getHistory().push(CommonPath.DEFAULT_PATH);
  }
}

export function* autoLoginFlow() {
  while (1) {
    const authData = getLoginStorageData();
    if (tokenChecker(authData)) {
      yield put(loginSuccess(authData as any));
    } else {
      yield put(refreshToken());
    }

    yield take(LOGOUT);
  }
}
